// base button

.button {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 10rem;

  text-decoration: none;

  color: #ffffff;

  font-family: 'montserrat', 'sans-serif';
  font-size: 16rem;
  font-weight: 500;
  line-height: 1.4;
}

.button__icons {
  display: flex;

  margin-right: 12rem;

  & > :not(:last-child) {
    margin-right: 3rem;
  }

  img {
    width: 27rem;
  }
}

.button--freebet-parallelogram {
  position: relative;

  padding: 20rem 29rem;

  text-transform: uppercase;
  white-space: nowrap;

  color: #000000;

  font-size: 16rem;
  font-weight: 700;
  line-height: 1;

  &::before {
    @include parallelogram();

    position: absolute;
    z-index: -1;

    width: 100%;
    height: 100%;

    content: '';
    transition: $ease;

    background-color: #ffffff;
  }
}
