@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 900;
  src: url("montserrat-black.518e57c7.woff2") format("woff2"), url("montserrat-black.15224e66.woff") format("woff");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 800;
  src: url("montserrat-extra-bold.5a58940c.woff2") format("woff2"), url("montserrat-extra-bold.63e09a29.woff") format("woff");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 700;
  src: url("montserrat-bold.447a2a3e.woff2") format("woff2"), url("montserrat-bold.faadd583.woff") format("woff");
}

@font-face {
  font-family: montserrat;
  font-style: normal;
  font-weight: 500;
  src: url("montserrat-medium.3e1d2f02.woff2") format("woff2"), url("montserrat-medium.8e8ffb82.woff") format("woff");
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  margin: 0;
}

html, body {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  line-height: 1.45;
}

img, picture, video, canvas, svg {
  max-width: 100%;
  display: block;
}

input, button, textarea, select {
  font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}

html, body {
  background-color: #000;
  font-family: montserrat;
  font-size: .313vw;
  position: relative;
}

body {
  min-height: auto;
  max-width: 100vw;
}

* {
  -webkit-tap-highlight-color: transparent;
  scrollbar-width: 8rem;
}

body {
  scrollbar-color: #45236f #000;
}

body::-webkit-scrollbar {
  width: 8rem;
}

body::-webkit-scrollbar-track {
  background: #000;
}

body::-webkit-scrollbar-thumb {
  background-color: #45236f;
  border: none;
  border-radius: 20rem;
}

.button {
  color: #fff;
  justify-content: center;
  align-items: center;
  padding: 10rem;
  font-family: montserrat, "sans-serif";
  font-size: 16rem;
  font-weight: 500;
  line-height: 1.4;
  text-decoration: none;
  display: flex;
}

.button__icons {
  margin-right: 12rem;
  display: flex;
}

.button__icons > :not(:last-child) {
  margin-right: 3rem;
}

.button__icons img {
  width: 27rem;
}

.button--freebet-parallelogram {
  text-transform: uppercase;
  white-space: nowrap;
  color: #000;
  padding: 20rem 29rem;
  font-size: 16rem;
  font-weight: 700;
  line-height: 1;
  position: relative;
}

.button--freebet-parallelogram:before {
  z-index: -1;
  width: 100%;
  height: 100%;
  content: "";
  background-color: #fff;
  border-radius: 6rem;
  transition: all .4s ease-in-out;
  position: absolute;
  -webkit-transform: skew(-18deg);
  transform: skew(-18deg);
}

.no-scroll {
  overflow: hidden;
}

.header {
  z-index: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.header__logo {
  margin: 20rem 0 0 20rem;
}

.logo {
  width: 105rem;
  display: block;
}

.logo img {
  width: 100%;
}

.header__buttons {
  margin: 20rem 20rem 0 0;
  display: flex;
}

.header__buttons > :not(:last-child) {
  margin-right: 10rem;
}

.download-ios img, .download-android img {
  width: 38rem;
}

.promo {
  width: 100%;
  height: auto;
  color: #fff;
  background-color: #000;
  background-image: url("bg.2be87b4d.webp");
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  padding: 292rem 20rem 10rem;
  font-size: 16rem;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .promo {
    background-image: url("bg.ef1421bc.webp");
  }
}

.promo__title {
  letter-spacing: -.05em;
  text-transform: uppercase;
  margin-bottom: 9rem;
  font-size: 26rem;
  font-weight: 900;
  line-height: 1;
}

.promo__title span {
  white-space: nowrap;
  color: #00c7b1;
}

.promo__subtitle {
  letter-spacing: -.05em;
  margin-bottom: 20rem;
  font-size: 14rem;
  font-weight: 500;
  line-height: 1.2;
}

.promo__body {
  height: 100%;
  flex-flow: column;
  justify-content: space-evenly;
  display: flex;
}

.promo__stages {
  width: 100%;
  height: 30rem;
  justify-content: center;
  margin: 0 auto;
  display: flex;
}

.promo__stages img {
  height: 100%;
}

.promo__buttons {
  z-index: 1;
  flex-flow: column;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.promo__buttons .button {
  margin: 0 auto;
}

.promo__buttons .button:first-child {
  margin-bottom: 20rem;
}

.footer {
  z-index: 1;
  width: 100%;
  height: auto;
  background-color: #000;
  flex-flow: column;
  justify-content: space-between;
  align-items: center;
  padding: 15rem 12rem;
  display: flex;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
}

.payments {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20rem;
  display: flex;
}

.payments__visa, .payments__mc {
  width: 30rem;
}

.payments__peace {
  width: 38rem;
}

.payments__qiwi, .payments__pay {
  width: 40rem;
}

.apps {
  width: 100%;
  flex-flow: wrap;
  justify-content: space-between;
  margin-bottom: 10rem;
  display: flex;
}

.app {
  width: 140rem;
  margin-bottom: 10rem;
}

.app img {
  width: 100%;
  display: block;
}

.soc {
  width: 100%;
  align-items: center;
  display: flex;
}

.soc__icon {
  width: 20rem;
}

.soc__icon:first-child {
  color: #fff;
  white-space: nowrap;
  align-items: center;
  margin-right: auto;
  font-size: 8rem;
  line-height: 1;
  display: flex;
}

.soc__icon:first-child img {
  margin-right: 6rem;
}

.soc__icon:not(:first-child) {
  margin-left: 20rem;
}

.soc__icon img {
  width: 100%;
  display: block;
}

/*# sourceMappingURL=index.8f22014c.css.map */
