@import 'variables';
@import 'fonts';
@import 'mixins';
@import 'reset';

// 1px ===  1rem
html,
body {
  position: relative;

  background-color: #000000;

  font-family: 'montserrat';
  font-size: 0.313vw;
}

body {
  min-height: auto;
  max-width: 100vw;
}

* {
  -webkit-tap-highlight-color: transparent;
}

/* Работает в Firefox */
* {
  scrollbar-width: 8rem;
}

body {
  scrollbar-color: darken($lavand, 20%) #000000;
}

/* Работает в Chrome, Edge и Safari */
body::-webkit-scrollbar {
  width: 8rem;
}

body::-webkit-scrollbar-track {
  background: #000000; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  border: none; /* creates padding around scroll thumb */
  border-radius: 20rem; /* roundness of the scroll thumb */
  background-color: darken($lavand, 20%); /* color of the scroll thumb */
}
