// для корректных брейкпоинтов на 1000px
$sm: 999.9px;

$min: 800rem;

$lazur: #00c7b1;
$lavand: #753bbd;

$ease: all 0.4s ease-in-out;
$parallelogram: skew(-18deg);
$antiparallelogram: skew(18deg);
