.promo {
  @include retina() {
    background-image: url('../images/bg/bg.jpg?as=webp&');
  }

  position: relative;

  display: flex;
  overflow: hidden;

  width: 100%;
  height: auto;
  padding: 292rem 20rem 10rem;

  color: #ffffff;
  background-color: #000000;
  background-image: url('../images/bg/bg.jpg?as=webp&width=640');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: 100% auto;

  font-size: 16rem;
}

.promo__title {
  margin-bottom: 9rem;

  letter-spacing: -0.05em;
  text-transform: uppercase;

  font-size: 26rem;
  font-weight: 900;
  line-height: 1;
  span {
    white-space: nowrap;

    color: $lazur;
  }
}

.promo__subtitle {
  margin-bottom: 20rem;

  letter-spacing: -0.05em;

  font-size: 14rem;
  font-weight: 500;
  line-height: 1.2;
}

.promo__body {
  display: flex;
  flex-flow: column;
  justify-content: space-evenly;

  height: 100%;
}

.promo__stages {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 30rem;
  margin: 0 auto;

  img {
    height: 100%;
  }
}

.promo__buttons {
  position: relative;
  z-index: 1;

  display: flex;
  flex-flow: column;

  margin: 0 auto;

  .button {
    margin: 0 auto;
    &:first-child {
      margin-bottom: 20rem;
    }
  }
}
