// configs
@import './configs/config';

// universal components
@import './components/buttons';
@import './components/universal';

// sections
@import './components/header';
@import './components/promo';
@import './components/footer';

