.footer {
  position: relative;
  z-index: 1;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  flex-flow: column;
  justify-content: space-between;

  width: 100%;
  height: auto;
  padding: 15rem 12rem;

  background-color: #000000;
}

.payments {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 20rem;

  &__visa {
    width: 30rem;
  }
  &__mc {
    width: 30rem;
  }
  &__peace {
    width: 38rem;
  }
  &__qiwi {
    width: 40rem;
  }
  &__pay {
    width: 40rem;
  }
}

.apps {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;

  width: 100%;
  margin-bottom: 10rem;
}

.app {
  width: 140rem;
  margin-bottom: 10rem;

  img {
    display: block;

    width: 100%;
  }
}

.soc {
  display: flex;
  align-items: center;

  width: 100%;
}

.soc__icon {
  width: 20rem;
  &:first-child {
    margin-right: auto;
    display: flex;

    color: #ffffff;

    font-size: 8rem;
    line-height: 1;
    white-space: nowrap;
    align-items: center;
    img{
      margin-right: 6rem;
    }
  }
  &:not(:first-child) {
    margin-left: 20rem;
  }

  img {
    display: block;

    width: 100%;
  }
}
