.header {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.header__logo {
  margin: 20rem 0 0 20rem;
}

.logo {
  display: block;

  width: 105rem;

  img {
    width: 100%;
  }
}

.header__buttons {
  display: flex;

  margin: 20rem 20rem 0 0;
  & > :not(:last-child) {
    margin-right: 10rem;
  }
}

.download-ios,
.download-android {
  img {
    width: 38rem;
  }
}

